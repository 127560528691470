<template>
  <div class="currencies-wrapper">
    <div class="currencies">
      <div class="currencies__item" @click="onClickCurrencyItem('ticket')">
        <div class="currencies__item-img"><img :src="require('@/assets/ticket.svg')" alt=""></div>
        <div class="currencies__item-count" style="min-width: 64px">
          {{ getPlayerTickets }}
          <span class="currencies__ticket-time" v-if="player.ticketRenewTime != 0 && !this.player.isImmortal">{{ ticketTimer }}</span>
        </div>
      </div>
      <div class="currencies__item" @click="onClickCurrencyItem('coin')">
        <div class="currencies__item-img"><img :src="require('@/assets/coin.svg')" alt=""></div>
        <div class="currencies__item-count" :style="{ fontSize: coinFontSize}">
          {{ player.coins }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  data() {
    return {
      ticketTimer: null,
      ticketTimerInterval : null,
    }
  },
  computed: {
    ...mapState(["player"]),
    getPlayerTickets() {
      return this.player.isImmortal ? '∞' : this.player.powerups.ticket
    },
    coinFontSize() {
      if (this.player.coins > 99999) {
        return '9.3px'
      } else if (this.player.coins > 9999) {
        return '12px'
      }
      return '16px';
    },
  },
  watch: {
    "player.ticketRenewTime": function() {
      this.setTicketTimer()
    },
  },
  created() {
    this.setTicketTimer();
  },
  beforeDestroy() {
    if(this.ticketTimerInterval) clearInterval(this.ticketTimerInterval);
  },
  methods: {
    ...mapActions(["getPlayerInfo"]),
    setTicketTimer() {
      if(this.player.ticketRenewTime > 0) {
        var timer = this.player.ticketRenewTime, minutes, seconds;
        if(this.ticketTimerInterval) clearInterval(this.ticketTimerInterval);
        this.ticketTimerInterval = setInterval(() => {
          minutes = parseInt(timer / 60, 10)
          seconds = parseInt(timer % 60, 10);

          minutes = minutes < 10 ? "0" + minutes : minutes;
          seconds = seconds < 10 ? "0" + seconds : seconds;

          this.ticketTimer = minutes + ":" + seconds;

          if (--timer < 0) {
              timer = null;
              if(this.ticketTimerInterval) clearInterval(this.ticketTimerInterval);
              this.getPlayerInfo({id : this.player.id})
          }
        }, 1000);
      }
    },
    onClickCurrencyItem(btn) {
      this.$emit('btnAction', btn)
      
      if (this.$route.name != 'store') {
        this.$modal.show("store-modal")
      }
    }
  }
}
</script>