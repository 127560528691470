<template>
  <div>
    <loading :active.sync="isLoading" :is-full-page="true" :backgroundColor="`#000000`" :color="`#FFB74F`" :blur="`7px`"
      :opacity="0.5" :width="64" :height="64">
    </loading>
    <h2 class="text-danger text-center mt-4" v-html="this.message" v-if="!this.isLoading && this.message"></h2>
    <div id="payment-form" class="px-8 py-4 scrollable-container">
      <div class="flex items-center justify-between mb-8">
        <h2>Kart Bilgileri</h2>
        <div v-if="hasPaymentMethods" class="underline cursor-pointer" @click="onClickPaymentMethodChangeButton">
          <span v-if="paymentMethodType == 's'">Başka bir Kart ile Ödeme Yap</span>
          <span v-if="paymentMethodType == 'n'">Kayıtlı Kart ile Ödeme Yap</span>
        </div>
      </div>
      <div class="block" v-if="paymentMethodType == 's'">
        <div class="payment-methods">
          <div class="payment-methods__item p-2 mt-1" :class="{ active: paymentMethod == activePaymentMethod }"
            v-for="paymentMethod in availablePaymentMethods" :key="paymentMethod.id"
            @click="onClickPaymentMethod(paymentMethod)">
            <span class="payment-methods__radio"></span>
            <div class="payment-methods__item-container">
              <p class="flex-shrink">**** **** **** {{ paymentMethod.card.lastFour }}</p>
              <p class="flex-none">{{ paymentMethod.card.expMonth }}/{{ paymentMethod.card.expYear }}</p>
              <p class="flex-none mt-1"><img :src="require(`@/assets/brand-logos/${paymentMethod.card.brand}.svg`)"
                  :alt="paymentMethod.card.brand"></p>
            </div>
          </div>
        </div>
        <button id="submit" class="mt-6" @click="handleWithPaymentMethod(activePaymentMethod.id)" v-if="!loading">
          <div class="spinner mr-2" :class="{ 'hidden': !isLoading }" id="spinner"></div>
          <span id="button-text">Ödeme Yap</span>
        </button>
      </div>
      <div v-else-if="paymentMethodType == 'n'">
        <div class="payment-element"></div>
        <div v-if="loading">
          <h1 class="text-center">
            Ödeme Ekranı Hazırlanıyor...
          </h1>
        </div>
        <label for="kvkk" class="block mt-5">
          <input id="savePaymentMethod" type="checkbox" v-model="savePaymentMethod" required name="savePaymentMethod"
            class="mr-1"> Kartımı Sakla
        </label>
        <div class="flex items-center" style="font-size: 10px; margin:0 10px auto">
          * Kart bilgileriniz firmamız tarafından değil ödeme aracı kurumu Stripe tarafından uluslararası standartlara
          uygun tutulmaktadır.
        </div>
        <button id="submit" class="mt-6" @click="handleSubmit" v-if="!loading">
          <div class="spinner mr-2" :class="{ 'hidden': !isLoading }" id="spinner"></div>
          <span id="button-text">Ödeme Yap</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Feed from "@/mixins/Feed"

export default {
  mixins: [Feed],
  name: "StripeComponent",
  data() {
    return {
      clientSecret: null,
      stripe: null,
      elements: null,
      isLoading: false,
      loading: false,
      message: null,
      savePaymentMethod: true,
      activePaymentMethod: null,
      paymentMethodType: null
    }
  },
  props: {
    transactionId: {
      type: String,
      default: null
    },
    product: {
      type: Object,
      default: null
    },
    player: {
      type: Object,
      default: null
    },
    availablePaymentMethods: {
      type: Array,
      default: () => []
    }
  },
  created() {
    // eslint-disable-next-line no-undef
    this.stripe = new Stripe(process.env.VUE_APP_STRIPE_KEY, {
      locale: 'tr'
    });
    // this.getStripeKey(this.transactionId).then(response => {
    //   this.clientSecret = response.data.result.set.value;
    // })
    if (this.availablePaymentMethods.length > 0) {
      this.activePaymentMethod = this.availablePaymentMethods[0];
    }
  },
  mounted() {

    this.getStripeKey(this.transactionId).then(response => {
      this.clientSecret = response.data.result.set.value;
      if (this.hasPaymentMethods) {
        this.paymentMethodType = 's';
      }
      else {
        this.paymentMethodType = 'n';
        this.openModal();
      }
    })

  },
  computed: {
    hasPaymentMethods() {
      return this.availablePaymentMethods.length > 0
    }
  },
  watch: {
    paymentMethodType: function (val) {
      if (val == 'n') {
        this.openModal();
      }
    }
  },
  methods: {
    ...mapActions(['getStripeKey', 'stripeValidation']),
    async handleSubmit() {
      this.message = null;
      this.isLoading = true;
      let elements = this.elements;
      // eslint-disable-next-line no-undef
      await this.stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: window.location.href
        },
        redirect: 'if_required'
      }).then(response => {
        if (!response.error) {
          if (!this.savePaymentMethod) {
            response.savePaymentMethod = false
          }

          this.completeStripePurchase(response)
        } else {
          this.message = response.error.message;
          this.isLoading = false;
        }
      }).catch((e) => {
        console.log(e)
      });
    },
    openModal() {
      this.isLoading = true;
      this.$nextTick(() => {
        this.elements = this.stripe.elements({ clientSecret: this.clientSecret });
        const paymentElement = this.elements.create("payment");
        paymentElement.mount(".payment-element");
        paymentElement.on('ready', () => {
          this.isLoading = false;
          this.loading = false;
        })
      })

    },
    onClickPaymentMethodChangeButton() {
      this.paymentMethodType = this.paymentMethodType == 's' ? 'n' : 's';
    },
    onClickPaymentMethod(paymentMethod) {
      this.activePaymentMethod = paymentMethod;
    },
    async handleWithPaymentMethod(pmId) {
      this.message = null;
      this.isLoading = true;
      // eslint-disable-next-line no-undef
      await this.stripe.confirmCardPayment(this.clientSecret, {
        payment_method: pmId
      }).then(response => {
        if (!response.error) {
          this.completeStripePurchase(response)
        } else {
          this.message = response.error.message;
          this.isLoading = false;
        }
      }).catch((e) => {
        console.log(e)
      });
    },
    completeStripePurchase(data) {
      var returnObj = {
        "success": false,
        "message": null,
        "product": this.product
      }

      data.savePaymentMethod = this.savePaymentMethod

      this.stripeValidation({ transactionId: this.transactionId, data: data }).then(() => {
        this.isLoading = false;
        returnObj.success = true
        this.$emit('onCallback', returnObj)
        this.feedAllEvent('sales_generated', this.product.price, 'ecommerce', this.product.currency, this.product.currency)
        this.feedAllEvent('purchase_product', this.product.price, 'ecommerce', this.product.sku, this.product.sku)
      }).catch(error => {
        this.isLoading = false;
        returnObj.message = error.message
        this.$emit('onCallback', returnObj)
      })
    }
  }
}
</script>

<style scoped>
.scrollable-container {
  max-height: 400px;
  overflow-y: auto;
}
</style>