<template>
    <div ref="tooltipWrapper" class="cursor-pointer tooltip" :class="{giftbox: isTooltip, giftlist: !isTooltip}" @click.prevent="showTooltip">
        <div ref="tooltip" :class="[{'tooltip__content': isTooltip}, 'isActive', position]" :style="[{'top': positionTop + 'px'}, {'right': positionRight + 'px'}]" v-if="show || !isTooltip">
            <div class="flex" :class="{'flex-wrap':!isTooltip || isOutOfView}">
                <span class="flex flex-row items-center" :class="{'mb-2':!isTooltip}" v-for="(rewardDetail, i) in rewardDetails" :key="`${rewardDetail}-${i}`">
                    <img :src="require(`@/assets/store/${rewardDetail.icon}`)" alt="" class="mx-1" v-if="rewardDetail.icon" /> {{rewardDetail.label}}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "reward-box",
  props: {
      instructions: {
          type: Object,
          default: () => {}
      },
      isTooltip: {
        type: Boolean,
        default: true
      },
      position: {
          type: String,
          default: 'right'
      },
      scrollParent: {
        type: [Document, String],
        default: () => { return document }
      }
  },
  data() {
      return {
          show: false,
          isOutOfView: false,
          positionRight: 0,
          positionTop: 0
      }
  },
  computed: {
      scrollParentElement: function() {
        return typeof this.scrollParent == "string" ? document.getElementById(this.scrollParent) : this.scrollParent;
      },
      rewardDetails: function() {
        var details = []
        let flattenInstructions = this.flattenInstructions()

        this._.forEach(flattenInstructions, (v, k) => {
            var label = v
            var icon = null
            switch(k) {
                case 'coins':
                    icon = 'coin-001.svg'
                    break;

                case 'rewind':
                    icon = 'rewind.svg'
                    break;

                case 'open':
                    icon = 'open.svg'
                    break;

                case 'change':
                    icon = 'skip.svg'
                    break;

                case 'hint':
                    icon = 'hint.svg'
                    break;
                
                case 'ticket':
                    icon = 'ticket-001.svg'
                    break;

                case 'immortality':
                    icon = 'ticket-immortal.svg'
                    label = v.validUntil
                    break;

                default:
                    icon = null;
                    break;
            }
            if(icon) details.push({icon, label});
        })

        return details
    }
  },
  methods: {
      flattenInstructions() {
        var flattenInstructions = {}

        this._.map(this.instructions, (v, k) => {
            if('powerups' == k) {
                this._.forEach(v, (pv, pk) => {
                    if(
                        ('immortality' == pk && pv.isActive) || 
                        pv > 0
                        ) {
                        flattenInstructions[pk] = pv
                    }
                })
            }else{
                if(v > 0) {
                    flattenInstructions[k] = v
                }
            }
        })

        return flattenInstructions
      },
      showTooltip() {
          if(!this.show) {
              this.show = true;
              this.$nextTick(() => {
                let elementWidth = this.$refs.tooltip.offsetWidth;
                
                const windowWidth =  window.innerWidth;
                var parentRect = this.$refs.tooltipWrapper.getBoundingClientRect();
                this.positionRight = windowWidth - parentRect.right - 30;
                this.$nextTick(() => {
                  var rect = this.$refs.tooltip.getBoundingClientRect();
                  this.isOutOfView = false;
                  if(rect.left < 0) {
                    elementWidth = elementWidth + rect.left;
                    this.isOutOfView = true;
                  }
                  if(rect.right > windowWidth) {
                    elementWidth = elementWidth - (rect.right - windowWidth)
                    this.isOutOfView = true;
                  }
                  
                  this.$refs.tooltip.style.width = `${elementWidth}px`;
                  this.$nextTick(() => {
                    let elementHeight = this.$refs.tooltip.offsetHeight;
                    this.positionTop = parentRect.top - elementHeight - 6;
                  });
                  document.addEventListener("mousedown", this.hideTooltip)
                  this.scrollParentElement.addEventListener("scroll", this.hideTooltip)
                })
              })
          }
      },
      hideTooltip(e) {
        if(e.type != "scroll" && this.$el == e.target.closest(".tooltip")) {
            return;
        }
        this.show = false;
        document.removeEventListener("mousedown", this.hideTooltip)
        this.scrollParentElement.removeEventListener("scroll", this.hideTooltip)
      }
  }
}
</script>