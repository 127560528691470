<template>
  <div class="home">
    <img style="display:none;" @load="onImgLoaded" src="@/assets/background.svg" alt="">
    <div class="flex flex-col items-center justify-center">
      <transition name="fade" v-if="pageLoaded">
        <div>
          <div class="home-background w-full mb-8" >
            <img src="@/assets/background.svg" alt="">
            <banner v-for="banner in banners" :key="banner.id" :banner="banner" @expired="removeBanner(banner)"></banner>
          </div>
          <!-- <div class="flex justify-center items-center mb-8 px-2">
            <router-link :to="{name: 'store'}">
              <img src="@/assets/gb-banner.png" class="w-100" alt="">
            </router-link>
          </div> -->
        </div>
      </transition>
      <div class="flex flex-col">
        <a class="btn btn--primary self-center mb-4" :href="redirectUrl" :class="{heartbeat: loadAnimation}" target="_blank" v-if="this.instagramCheck" download>HEMEN OYNA</a>
        <button class="btn btn--primary self-center mb-4" :class="{heartbeat: loadAnimation}" @click="onClickLeagueGameButton" v-if="!this.instagramCheck">HEMEN OYNA</button>
        <!--div class="coupon-promo coupon-promo__pointer" @click="onClickLeagueGameButton">
          <div class="ml-6 mt-8">
            <h1 class="text-center home-promo flex flex-col items-center mb-8">
              <div>Ligde <strong class="home-promo__underline-orange">Her Hafta</strong></div>
              <div class="flex items-center">Toplam <div class="home-promo__circle-gray font-semibold ml-4"><span>675 ₺</span></div></div>
              <div class="home-promo__underline-gray font-semibold">HEDİYE ÇEKİ!!</div>
            </h1>
          </div>
          <img class="coupon-promo__img" src="@/assets/shopping-cart.svg" alt="">
        </div>
        <div-- class="flex items-center mt-4 mx-6 justify-center">
          <a href="https://www.momento.com.tr/" target="_blank" rel="nofollow"><img class="mr-2" style="height: 40px" src="@/assets/moment-logo.svg" alt=""></a> işbirliği ile her hafta hediye çekleri!
        </div-->
        <navigation-bottom class="mt-8 mb-4"></navigation-bottom>
      </div>
      <v-footer></v-footer>
      <league-game-modal ref="leagueGameModal" v-if="this.$store.state.authenticated" @onCreate="onCreateLeagueGame"></league-game-modal>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import LeagueGameModal from '@/views/components/LeagueGameModal'
import NavigationBottom from '@/views/components/NavigationBottom.vue'
import VFooter from "@/views/components/Footer";
import Banner from "@/views/components/Banner";
import Feed from "@/mixins/Feed"
//import * as Sentry from "@sentry/vue";


export default {
  metaInfo:{
    title: 'Anasayfa',
    meta: [
      { property: 'og:title', content:'Anasayfa - Adam Asmaca Oyunu' }
    ]
  },
  mixins: [Feed],
  data(){
    return {
      imgLoaded: false,
      loadAnimation: false,
      usernames: ["Kedi858", "Köpek12518", "Timsah8149", "Koala1516", "Baykuş5712", "Tilki6871", "osmannn", "sılayh", "mandalina56", "ayhan14", "yildirimkarakus", "hkuzdzal", "ksitty", "poptart1251", "ahenk34", "holdenbower"],
      score: {
        username: "",
        point: 0,
      },
      bannerInterval: null,
      bannerId: 0,
      banners: []
    }
  },
  created() {
    /*let playerId = this.player.id
    Sentry.configureScope(function(scope) {
      scope.setTag("application", "web");
      scope.setUser({
        id: playerId
      });
    });*/

    this.createRandomBanner();
  },
  mounted() {
    if(this.$store.state.authenticated) {
      this.feedAllEvent('view_landing', null, 'engagement')
    }
  },
  computed: {
    ...mapState(["player"]),
    pageLoaded() {
      return this.imgLoaded;
    },
    instagramCheck(){
      let useragent = navigator.userAgent.toString();
      if(useragent.includes('Instagram')){
        return true;
      }else{
        return false;
      }
    },
    redirectUrl(){
      return window.location.href + '#login';
    }
  },
  watch: {
    pageLoaded() {
      if(this.pageLoaded) {
        this.addBanner();
        this.bannerInterval = setInterval(() => {
          this.addBanner();
        }, 2000);
        setTimeout(() => {
          this.loadAnimation = true;
        }, 1000)
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.bannerInterval);
  },
  beforeCreate() {
    this.$OneSignal.showSlidedownPrompt()
  },
  components: {
    LeagueGameModal, NavigationBottom, VFooter, Banner
  },
  methods: {
    ...mapActions(["createGame", "getPlayerInfo", "retrieveProductGroups","getFreeGame", "feedHourlyStat","checkOpenGame", "setRewardType", "retrieveAdPlacement", "getTopTen", "createGame"]),
    ...mapGetters(["checkTicket", "hasImmortality"]),
    createRandomBanner() {
      return {
        id: this.bannerId++,
        username : this.usernames[this._.random(0, this.usernames.length - 1)],
        point : this._.random(3,24)
      }
    },
    addBanner() {
      // this.banners.push(this.createRandomBanner());
    },
    removeBanner(banner) {
      this.banners.splice(this.banners.indexOf(banner), 1);
    },
    showBanner() {
      this.$refs.scoreBanner.classList.remove("puff-out-center");
      this.$refs.scoreBanner.classList.add("slide-in-blurred-bottom");
      setTimeout(() => {
        this.hideBanner();
      },2000);
    },
    hideBanner() {
      this.$refs.scoreBanner.classList.add("puff-out-center");
      this.$refs.scoreBanner.classList.remove("slide-in-blurred-bottom");
      setTimeout(() => {
        this.createRandomBanner();
        this.showBanner();
      },100);
    },
    onImgLoaded() {
      this.imgLoaded = true;
    },
    onClickExercise() {
      this.feedAllEvent('click_button', 'training', 'interaction', this.$route.path)
      if(this.$store.state.authenticated) {
        this.createGame({gameType: '0'}).then(() => {
          this.$router.push({name: "game", params: {id: 'free'}, query: this.$route.query})
        })
      }else {
        this.getFreeGame().then(() => {
          this.$router.push({name: "freeGame", params: {id: 'freeGame'}, query: this.$route.query})
        })
      }
    },
    onClickLeagueGameButton() {
      this.feedAllEvent('click_button', null, 'league', 'interaction', null)
      this.checkOpenGame().then(() => {
        if (this.checkTicket()) {
          if(this.$store.state.openGame) {
            this.getTopTen().then(() => {
              this.$refs.leagueGameModal.show();
            })
          }else{
            Promise.all([this.createGame({gameType: '1'}), this.getTopTen()]).then(() => {
              this.$refs.leagueGameModal.show()
            }).catch(() => {
              this.$refs.leagueGameModal.show()
            })
          }
        } else {
          this.$modal.show("promo-modal", {promoType: "ticket"});
        }
      });
    },
    onCreateLeagueGame() {
      if(!this.hasImmortality) {
        this.feedAllEvent('tickets_spent', null, 'gameplay')
      }
    }
  }
}
</script>