<template>
  <modal v-if="this.$store.state.player" name="promo-modal" classes="modal" :minWidth="200"
         :minHeight="400"
         :maxWidth="480"
         :scrollable="true"
         :reset="true"
         :adaptive="true"
         :clickToClose="false"
         width="90%"
         height="auto"
         @before-open="onBeforeOpen">
    
    <div class="flex justify-end relative">
      <button @click="onClickHideButton" class="absolute right-0 mr-4 mt-4">
        <img src="@/assets/times-black.svg" alt="">
      </button>
    </div>
    <div class="flex flex-col items-center">
      <h1 class="my-4 font-semibold">ÜZGÜNÜZ !</h1>
      <img class="mb-4" src="@/assets/icon-sad.svg" alt="">
      <h3>Oynamak için yeterli 
        <template v-if="promoType == 'ticket'">
          biletiniz 
        </template>
        <template v-else-if="promoType == 'open'">
          harf açma gücünüz
        </template>
        <template v-if="promoType == 'rewind'">
          geri sarma gücünüz
        </template>
        <template v-if="promoType == 'change'">
          pas geçme gücünüz
        </template>
        <template v-if="promoType == 'hint'">
          ipucu gücünüz
        </template>
      yok.</h3>
      <div class="flex justify-center items-center mb-8">
        <template v-if="promoType == 'ticket'">
          <div class="icon-circle mr-4">
            <img src="@/assets/icon-ticket.svg" alt="">
          </div>
          <span class="text-2xl font-semibold">BİLET : {{ this.$store.state.player.powerups.ticket }}</span>
        </template>
        <template v-else-if="promoType == 'open'">
          <div class="icon-circle mr-4">
            <img style="max-height:32px" src="@/assets/store/open.svg" alt="">
          </div>
          <span class="text-2xl font-semibold">HARF AÇMA : {{ this.$store.state.player.powerups.open }}</span>
        </template>
        <template v-else-if="promoType == 'rewind'">
          <div class="icon-circle mr-4">
            <img style="max-height:32px" src="@/assets/store/rewind.svg" alt="">
          </div>
          <span class="text-2xl font-semibold">GERİ SARMA : {{ this.$store.state.player.powerups.rewind }}</span>
        </template>
        <template v-else-if="promoType == 'change'">
          <div class="icon-circle mr-4">
            <img style="max-height:32px" src="@/assets/store/skip.svg" alt="">
          </div>
          <span class="text-2xl font-semibold">PAS GEÇME : {{ this.$store.state.player.powerups.change }}</span>
        </template>
        <template v-else-if="promoType == 'hint'">
          <div class="icon-circle mr-4">
            <img style="max-height:32px" src="@/assets/store/hint.svg" alt="">
          </div>
          <span class="text-2xl font-semibold">İPUCU : {{ this.$store.state.player.powerups.hint }}</span>
        </template>
      </div>
      <div class="flex items-center justify-center mb-4 w-full px-2">
        <button v-if="product" @click="onClickPurchaseSpecialOffer">
          <div style="width:100% !important" class="store-item store-item--bold justify-self-center">
            <h2>SANA ÖZEL</h2>
            <div class="store-item__img">
              <img :src="product.image" alt="title">
              <span class="text-stroke" v-if="product.rewardInfo.coins > 0"><small>x</small>{{ product.rewardInfo.coins }}</span>
              <span class="text-stroke" v-else-if="product.rewardInfo.powerups.open > 0"><small>x</small>{{ product.rewardInfo.powerups.open }}</span>
              <span class="text-stroke" v-else-if="product.rewardInfo.powerups.rewind > 0"><small>x</small>{{ product.rewardInfo.powerups.rewind }}</span>
              <span class="text-stroke" v-else-if="product.rewardInfo.powerups.ticket > 0"><small>x</small>{{ product.rewardInfo.powerups.ticket }}</span>
              <span class="text-stroke" v-else-if="product.rewardInfo.powerups.change > 0"><small>x</small>{{ product.rewardInfo.powerups.change }}</span>
              <span class="text-stroke" v-else-if="product.rewardInfo.powerups.immortality.validUntil > 0" style="font-size: 16px"><small>+</small> {{ product.rewardInfo.powerups.immortality.validUntil }} Saat</span>
            </div>
            <div class="store-item__value">
              <span class="store-item__value-number">{{ product.price }}</span>
              <span class="store-item__value-currency">
                <template v-if="product.currency == 'TRY'">TL</template>
                <template v-else>
                  <img src="@/assets/coin-small.svg" alt="">
                </template>
              </span>
            </div>
          </div>
        </button>
        <router-link v-else :to="{name: 'store'}"  class="btn btn--secondary">
          MAĞAZA
        </router-link>
        <button v-if="showAd" class="btn btn--primary ml-8" @click="onClickRewardAdButton">
          <template v-if="promoType == 'ticket'">
            <h2 class="btn__title"><img  class="mx-1" style="max-height:18px" src="@/assets/icon-ticket.svg" alt=""> KAZAN</h2>
            <img src="@/assets/play-icon.svg" style="margin: auto" alt="">
            <span class="flex items-center">+1 BİLET</span>
          </template>
          <template v-else-if="promoType == 'change'">
            <h2 class="btn__title"><img  class="mx-1" style="max-height:18px" src="@/assets/store/skip.svg" alt=""> KAZAN</h2>
            <img src="@/assets/play-icon.svg" style="margin: auto" alt="">
            <span class="flex items-center">+1 PAS </span>
          </template> 
          <template v-else-if="promoType == 'open'">
            <h2 class="btn__title"><img  class="mx-1" style="max-height:18px" src="@/assets/store/open.svg" alt=""> KAZAN</h2>
            <img src="@/assets/play-icon.svg" style="margin: auto" alt="">
            <span class="flex items-center">+1 HARF AÇMA</span>
          </template>  
          <template v-else-if="promoType == 'rewind'">
            <h2 class="btn__title"><img  class="mx-1" style="max-height:18px" src="@/assets/store/rewind.svg" alt=""> KAZAN</h2>
            <img src="@/assets/play-icon.svg" style="margin: auto" alt="">
            <span class="flex items-center">+1 GERİ SARMA</span>
          </template>  
          <template v-else-if="promoType == 'hint'">
            <h2 class="btn__title"><img  class="mx-1" style="max-height:18px" src="@/assets/store/hint.svg" alt=""> KAZAN</h2>
            <img src="@/assets/play-icon.svg" style="margin: auto" alt="">
            <span class="flex items-center">+1 İPUCU</span>
          </template>  
        </button>
        <div class="ml-8 relative" v-if="promoType == 'ticket' && adTimer != null">
          <div class="btn btn--primary opacity-25">
            <h2 class="btn__title"><img  class="mx-1" style="max-height:18px" src="@/assets/icon-ticket.svg" alt=""> KAZAN</h2>
            <img src="@/assets/play-icon.svg" style="margin: auto" alt="">
            <span class="flex items-center">+1 BİLET</span>
          </div>
          <div class="btn__timer" v-if="promoType == 'ticket' && adTimer != null">
            <div class="flex flex-col items-center">
              <span>{{ adTimer }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import Feed from "@/mixins/Feed"
export default {
  name: "PromoModal",
  mixins: [Feed],
  data(){
    return {
      product: null,
      promoType: null,
      specialOfferSold: false,
      rewardGranted: false,
      gameCount: parseInt(sessionStorage.getItem('gameCount')),
      showAd: false,
      adTimer: null,
      adTimerInterval : null,

    }
  },
  computed: {
    ...mapState(["productsOfPromotions", "player", "appSettings", "productGroups"])
  },
  watch: {
    // "$store.state.player.powerups.ticket": function() {
    //   if(this.$store.state.player.powerups.ticket > 0 && this.promoType == "ticket") {
    //     this.hide();
    //   }
    // }
    "player.adRenewTime": function() {
      this.setAdTimer()
    },
  },
  created() {
    this.getProductsOfPromotions();

    if(this.promoType != null && this.promoType != 'ticket') { this.showAd = true }
    // kullanıcılar bulundukları lig sırasına göre % li olarak reklam izleyip bilet kazanabiliyordu
    //this.showAd = true
    /*let percent = 100
    let rank = this.player.playerStanding.currentRank
    Object.entries(this.appSettings.addRewardFrequency).forEach((val) => {
      let range = val[0].split("-")
      if (rank >= range[0] && rank <= range[1]) {
        percent = val[1]
      }
    })
    if(this.gameCount > 0 && this.gameCount % Math.floor(100/percent) == 0){
     this.showAd = true
    }*/
    this.setAdTimer();
  },
  methods: {
    ...mapActions(["getProductsOfPromotions", "purchaseStart", "purchaseProduct", "sendRewardForAd", "createGame", "getPlayerInfo", "retrieveProductGroups"]),
    ...mapGetters(["checkTicket", "hasImmortality"]),
    setAdTimer() {
      if(this.player.adWatchLimit > 0){
        this.showAd = true
      } else {
        if(this.player.adRenewTime > 0) {
          this.showAd = false
          var timer = this.player.adRenewTime, minutes, seconds;
          if(this.adTimerInterval) clearInterval(this.adTimerInterval);
          this.adTimerInterval = setInterval(() => {
            minutes = parseInt(timer / 60, 10)
            seconds = parseInt(timer % 60, 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            this.adTimer = minutes + ":" + seconds;

            if (--timer < 0) {
              timer = null;
              if(this.adTimerInterval) clearInterval(this.adTimerInterval);
              this.getPlayerInfo({id : this.player.id})
              this.showAd = true
              this.adTimer = null
            }
          }, 1000);
        }
      }
    },
    onClickHideButton() {
      if(this.$route.name == 'game' && this.promoType == "ticket") {
        this.getPlayerInfo({id : this.player.id}).then(() => {
          if (this.$route.name !== 'dashboard') {
            this.$router.push({name: "dashboard"});
          }
        })
      }
      this.hide();
    },
    onClickPurchaseSpecialOffer() {
      let product = this.product
      if (product.type == 'c' || product.type == 'r') {
        this.purchaseStart({
          qty: 1,
          productId: product.id
        }).then((response) => {
          this.$modal.hide("suggest-product-modal")
          if (this.checkTicket()) {
            this.createGame({
              gameType: 1
            })
          } else {
            if (this.$route.name !== 'dashboard') {
              this.$router.push({name: "dashboard"});
            }
          }
          this.$gtag.event('begin_checkout', {
            'currency': 'TRY',
            'checkout_step': 1,
            'value': response.data.result.set.totalAmount,
            'items': [
              {
                'id': response.data.result.set.product.sku,
                'name': response.data.result.set.product.title,
                'price': response.data.result.set.product.price,
                'quantity': response.data.result.set.quantity
              }
            ]

          })
          window.fbq('track', 'InitiateCheckout', {
            content_name: response.data.result.set.product.title,
            content_ids: [response.data.result.set.product.id],
            content_type: 'product',
            value: response.data.result.set.product.price,
            currency: response.data.result.set.product.currency
          });

          this.transactionId = response.data.result.set.transactionId;
          let paymentPayload = {
            transactionId: this.transactionId,
            product: response.data.result.set.product,
            productTL: response.data.result.set.totalAmount,
            player: this.player,
            paymentMethods: response.data.result.set.availablePaymentMethods
          }
          this.$modal.hide('promo-modal');
          this.$modal.show("payment-method-modal", paymentPayload)
        });

      } else {
        this.errorMsg = null
        if (this.player.coins < product.price) {
          this.productGroups.forEach((group) => {
            if(group.title.indexOf('KOİN') > -1){
              let stop = 0
              group.products.forEach((prod) => {
                if(prod.rewardInfo.coins + this.player.coins > product.price && stop == 0){
                  this.$modal.show("suggest-product-modal")
                  this.suggestedProduct = prod
                  stop = 1
                }
              })
            }
          });
        } else {
          this.purchaseProduct({id: product.id}).then(() => {
            this.$modal.show("result-modal", {
              message : "Tebrikler, ödeme başarılı.",
              success : true,
              product: product
            });

            this.feedAllEvent('purchase_product', product.price, 'ecommerce', product.sku, product.sku)
            this.feedAllEvent('coins_spent', product.price, 'ecommerce', this.mode == 'modal' ? 'in-game' : 'store', product.sku)
            if(this.mode == 'modal') {
              var productType = null
              switch(product.type) {
                case 't':
                  productType = 'tip'
                  break
                case 'o':
                  productType = 'open'
                  break
                case 'p':
                  productType = 'powerup'
                  break
                default:
                  productType = null
                  break
              }

              if(null != productType) {
                this.feedAllEvent('powerup_purchase', null, 'ecommerce', productType, productType)
              }
            }

            this.$emit('purchase', true)
          }).catch(() => {
            this.$emit('purchase', false)
            this.$modal.show("result-modal", {
              message : "Ödeme sırasında bir sorun oluştu. Lütfen daha sonra tekrar deneyiniz.",
              success : false
            });
          })
        }
      }
    },
    feedPromoEvent() {
      this.feedAllEvent('click_promo_purchase', null, 'ecommerce', this.$route.path)
    },
    onClickRewardAdButton() {
      this.showGoogleRewardAd();
    },
    showGoogleRewardAd() {
      let _this = this
      window.adBreak({
        type: 'reward',  // ad shows at start of next level
        name: 'google-reward',
          beforeAd: () => { },
          afterAd: () => {
            if(!this.rewardGranted) {
              this.show()
            }
            else {
              this.hide();
            }
          },
          beforeReward: (showAdFn) => {
            showAdFn();
          },
          adDismissed: () => {
            this.rewardGranted = false
          },
          adViewed: () => {
            this.rewardGranted = true;
            this.handleRewardCallback()
          }
      });
      if (this.checkTicket() && _this.promoType == 'ticket') {
        this.feedAllEvent('click_button', null, 'interaction', 're-play', 're-play')
        this.finalizeSend = false
        this.fbshare = 0
        this.$store.state.gamePlay = null;
        this.createGame({
          gameType: 1
        }).then(() => {
          _this.$emit("startGame")
          if(!this.hasImmortality()) {
            this.feedAllEvent('tickets_spent', null, 'gameplay')
          }
        })
        this.hide();
      }
    },
    handleRewardCallback() {
      var payload = {
        adPlacementCode: `add_1_${this.promoType}`,
        networkCode: "adi",
        data: {
          gameplayId: this.gameId
        }
      }

      this.sendRewardForAd(payload).then(() => {
        this.$emit("rewardGranted", this.promoType);
        this.hide();
      });
    },
    onBeforeOpen(event) {
      this.retrieveProductGroups()

      if(event.params && event.params.promoType) {
        this.promoType = event.params.promoType;
      }

      if(this.promoType == "ticket"){
        if(this.player.coins > 1000){
          //10 lu bilet satınalma
          let a
          for(a=0;a<this.productGroups.length;a++){
            this.product = this.productGroups[a].products.find(p => {
              return p.sku == 'ticket.X.0010' ? p.rewardInfo.powerups[this.promoType] > 0 : null
            })
            if(typeof this.product === 'object' && this.product !== null){
              return
            }
          }
        } else {
          //1 saatlik sınırsız bilet
          this.product = this.productsOfPromotions.find(p => {
            return p.sku == 'promo.T.00001' ? p.rewardInfo.powerups["immortality"] : null
          })
        }

      } else {
        // set product of promotion
        this.product = this.productsOfPromotions.find(p => {
          return p.rewardInfo && p.rewardInfo.powerups ? p.rewardInfo.powerups[this.promoType] > 0 : null
        })
      }
    },
    show() {
      if(this.productsOfPromotions.length == 0) {
        this.getProductsOfPromotions().then(() => {
            this.$modal.show("promo-modal");
            return;
        })
      }
      this.$modal.show("promo-modal")
    },
    hide() {
      this.$modal.hide("promo-modal");
    }
  }
}
</script>

<style scoped>

</style>